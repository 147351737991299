import React from 'react';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from './Navbar'
import 'typeface-roboto';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import themeFile from './theme.json';
import { getCookie} from './dwi-functions';
import { Redirect, BrowserRouter, Route, Switch} from 'react-router-dom';
import DashBoard from './DashBoard';
import AddProject from './add_project';
import EditProject from './edit_project'
import Login from './Login';
import Logout from './logout';
import SignUp from './SignUp';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SettingsDashboard from './settings/settings-dashboard';
import Refrigerants from './settings/refrigerants';
import UserTable from './settings/UserTable';
import LightVersion from './lightversion';



const theme = createMuiTheme(themeFile);
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
   
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },


}));



const site = {
    title: ''
  }

function App() {
  const classes = useStyles();
  // eslint-disable-next-line
  const [auth, setAuth] = React.useState(getCookie('_au'));




  return (
<MuiPickersUtilsProvider utils={MomentUtils}>
    <BrowserRouter>

   <Switch>

   <Route path="/register" component={SignUp} exact/>  
   <Route path="/login" component={Login} />
   <Route path="/liteversion" component={LightVersion} exact />  
   <ThemeProvider theme={theme}>
   <div className={classes.root}>
      <Navbar siteTitle={site.title}></Navbar>
      <main className={classes.content}>
        <div className={classes.toolbar} />
       
        {auth ? null : <Redirect to="/login" />}
          
       
            <Route path="/" component={DashBoard} exact />
            <Route path="/project/add" component={AddProject} exact />
            <Route path="/settings" component={SettingsDashboard} exact/>
            <Route path="/settings/refrigerants" component={Refrigerants} exact/>
            <Route path="/settings/sausers/" component={UserTable} exact/>
            <Route path="/project/edit/" component={EditProject}/>

            <Route path="/logout" component={Logout} />
          
      </main>
  </div>
      </ThemeProvider>
      </Switch>
  

</BrowserRouter>
</MuiPickersUtilsProvider>
  );
}

export default App;
