import React, { useState, useEffect } from 'react';
import { style } from './index.scss';
import MaterialTable from 'material-table'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Typography, Divider, Grid, Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NavBreadCrumb from '../../NavBreadCrumb';

const useStyles = makeStyles({
  root: {
      minWidth: 275,
  },
  table: {
      marginTop: '1em'
  },
  title: {
      borderBottom: '1px solid #000',
  },
  pos: {
      marginBottom: 12,
  },
});





const UserTable = (props) => {

  const classes = useStyles();
  const [hasError, setErrors] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState([true]);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrors(false);
  };

  async function fetchData() {
    const res = await fetch("https://gwp-api.digitalwerksinnovations.com/users");
    res
      .json()
      .then(res => setUsers(res))
      .then(res => setLoading(false))
      .catch(err => setErrors(true));
  }


  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div className={style}>
                  <NavBreadCrumb></NavBreadCrumb>
            <Divider></Divider>
     
     <div className={classes.table}>
      <MaterialTable
      
        isLoading={loading}
        columns={[
          { title: 'pkRecID', field: 'pkRecID', hidden: true },
          { title: 'User', field: 'name' },
          { title: 'Email', field: 'email' }
        ]}
        data={users}
        title="User Management"
        actions={[
          {
            icon: 'lock',
            tooltip: 'Reset Password',
            onClick: (event, rowData) => {
           //email function

           
            }
          },
          {
            icon: 'delete',
            tooltip: 'Delete User',
            onClick: (event, rowData) => {
              const u = 'https://gwp-api.digitalwerksinnovations.com/users/delete/'+rowData.userid;
              const res = fetch(u);
              if(res){
              fetchData();
              //console.log(rowData);
              }
            }
          },
          {
            icon: 'add',
            tooltip: 'Add User',
            isFreeAction: true,
            onClick: (event, rowData) => {
              // Do save operation
              console.log(rowData);
            }
          }

        ]}
      />
      </div>
      <Snackbar open={hasError} autoHideDuration={6000} onClose={handleClose}  >
        <Alert onClose={handleClose} severity="error">
          The data can not be pulled at this time.
                    <Button color="primary" onClick={window.location.reload}>Refresh</Button>
        </Alert>
      </Snackbar>

    </div>
  );
};

UserTable.propTypes = {

};

export default UserTable;
