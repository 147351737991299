import React, { useState, useEffect } from "react";
import { Typography, Grid, Paper, Button, Link } from "@material-ui/core";
import MaterialTable from "material-table";
import { numberWithCommas } from "../../../dwi-functions";
import { makeStyles } from "@material-ui/core/styles";
import exportFromJSON from 'export-from-json'
import targets from "../../../settings"
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar'
import Modal, { ModalManager } from '@material-ui/core/Modal';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import settings from "../../../settings";
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { set } from "lodash";
var _ = require("lodash");

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  totalContainer: {
    marginTop: "1em",
    display: "none",
  },
  appBar: {
    padding: "0 1em",
    paddingBottom: "1em",
    textAlign: "center",
    background: "rgba(2,45,131,1)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  paper: {
    backgroundColor: "#efefef",
    border: "1px solid #ECECEC",
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1),
    fontSize: '14px'
  },
  mattable: {
    marginBottom: "1em",
  },
  compliance: {
    //  backgroundColor: "#e6c6c5",
  },
  adjust: {
    minWidth: "100px"
  },
  margin:{
    color:theme.palette.primary.dark,
    top: '35px',
    left: "-25px",
    zIndex:"9999"
  
  }
}));

const GwpCalculationResults = (props) => {
  const [hasError, setErrors] = useState(false);
  const [cards, setCards] = useState([]);
  const [compliantClass2025, setCompliantClass2025] = useState('#e6c6c5');
  const [compliantClass2030, setCompliantClass2030] = useState('#e6c6c5');
  const [tempCard, setTempCard] = useState([]);
  const [SelectRef, setSelectRef] = useState([]);
  const [outputTable, setOutputTable] = useState([]);
  const [compliance2025, setCompliance2025] = useState('Non-compliant');
  const [compliance2030, setCompliance2030] = useState('Non-compliant');
  const [loading, setLoading] = useState([true]);
  const [showSnack, setShowSnack] = useState(false);
  const [showOutput, setShowOutput] = useState(true);
  const [DefautRef, setDefaultRef] = useState({ name: 'r744', value: 1 });
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [totals, setTotals] = useState([
    {
      racks: 0,
      lbs: 0,
      gwpContribution: 0,
      wagwp: 0,
      target: 0,
    },
  ]);




  const [refridgeTotals, setRefridgeTotals] = useState([
    {
      r744: 0
    },
  ]);



  const classes = useStyles();

  const handleClose = (event) => {
    // console.log(event.target.value);
    setShowSnack(false);
  };

  Number.prototype.toFixedNumber = function (digits, base) {
    var pow = Math.pow(base || 10, digits);
    return Math.round(this * pow) / pow;
  }


  const updateRefrigerant = (event) => {
    if (event.currentTarget.value <= 0) {
      return false;
    }

    var a = [];
    var topTotals = totals;
    a.push(refridgeTotals[0]);
    //console.log(event.currentTarget.dataset.ref);
    if (tempCard[DefautRef.name] === undefined) {
      tempCard[DefautRef.name] = [];
    }




    
    //console.log(   event.currentTarget.dataset.ref.toLowerCase());
    console.log(tempCard);

    //Set-up the array in the correct order  
    tempCard[event.currentTarget.dataset.ref.toLowerCase()] = _.sortBy(tempCard[event.currentTarget.dataset.ref.toLowerCase()], ['estimateCharge']);
    tempCard[event.currentTarget.dataset.ref.toLowerCase()] = _.reverse(tempCard[event.currentTarget.dataset.ref.toLowerCase()]);

    /**Fill in all the values need for the this refrigerant row */
    tempCard[event.currentTarget.dataset.ref.toLowerCase()][0]["refrigerant"] = DefautRef.name;
    tempCard[event.currentTarget.dataset.ref.toLowerCase()][0]["lookupRefrigerant"] = DefautRef.value;
    tempCard[event.currentTarget.dataset.ref.toLowerCase()][0].currentgwp = tempCard[event.currentTarget.dataset.ref.toLowerCase()][0].lookupRefrigerant * tempCard[event.currentTarget.dataset.ref.toLowerCase()][0].estimateCharge;

    
    

    /* push to the selected refrigerant */
    tempCard[DefautRef.name.toLowerCase()].push(tempCard[event.currentTarget.dataset.ref.toLowerCase()][0]);
    tempCard[event.currentTarget.dataset.ref.toLowerCase()].shift();


    var t = _.find(cards, function (o, k) {
      if (o.refrigerant === event.currentTarget.dataset.ref) {
        return o;
      }
    });

    var r = _.find(cards, function (o, k) {
      //console.log(o);
      if (o.refrigerant.toLowerCase() === DefautRef.name) {
        return o;
      }
    }) || [];

  

    var c = tempCard[event.currentTarget.dataset.ref.toLowerCase()].length;
    t.racks = c;
    t.lbs = _.sumBy(tempCard[event.currentTarget.dataset.ref.toLowerCase()], "estimateCharge");
    t.gwpContribution = _.sumBy(tempCard[event.currentTarget.dataset.ref.toLowerCase()], "currentgwp");
    //logger.warn(gwpValue);

    a[0][event.currentTarget.dataset.ref.toLowerCase()] = parseInt(event.currentTarget.value - 1);
    a[0][DefautRef.name] = (isNaN(a[0][DefautRef.name])===true ? 1 : parseInt(a[0][DefautRef.name] + 1) );
  
    //console.log( a);
  
  
    c = tempCard[DefautRef.name].length;
    r.racks = c;
    r.lbs = _.sumBy(tempCard[DefautRef.name], "estimateCharge");
    r.gwpContribution = _.sumBy(tempCard[DefautRef.name], "currentgwp");

    // console.log(tempCard);
    //console.log(topTotals[0].gwpContribution);
    //_.forEach(cards, function (value, key) {


    topTotals[0].gwpContribution = _.sumBy(cards, function (o) {
      // console.log(o);
      return parseInt(o.gwpContribution);
    });

    topTotals[0].lbs = _.sumBy(cards, function (o) {
      // console.log(o);
      return parseInt(o.lbs);
    });

    topTotals[0].racks = _.sumBy(cards, function (o) {
      // console.log(o);
      return parseInt(o.racks);
    });



    setTotals(topTotals);
    setCards(cards);
    setRefridgeTotals(a);

    var currentWAGWP = (totals[0].gwpContribution / totals[0].lbs).toFixed(0);

    if (currentWAGWP <= targets.gwpTarget.twentytwentyfive) {
      setCompliance2025('Compliant');
      setCompliantClass2025('#53d64d');
    } else {
      setCompliance2025('Non-compliant');
    }

    if (currentWAGWP <= targets.gwpTarget.twentythirty) {
      setCompliance2030('Compliant');
      setCompliantClass2030('#53d64d');
    } else {
      setCompliance2030('Non-compliant');
    }





  };

  function buildPreWork(data) {

    var returnArray = [];
    var sortedData = _.sortBy(data, ["refrigerant"]);
    sortedData = _.reverse(sortedData);
   // console.log(sortedData);
    var a ="";
    console.log(a);
    var tempA = [];

    returnArray[sortedData[sortedData.length-1]['refrigerant'].toLowerCase()] = tempA;

    _.forEach(sortedData, function (value, key) {
      //console.log(key);
      if (a !== value.refrigerant) {
        returnArray[a.toLowerCase()] = tempA;
        // refridgeTotals[0][a.toLowerCase()] = tempA.length;
        returnArray['r744'] = [];
        a = value.refrigerant;
        tempA = [];
      }

      tempA.push(value);
      refridgeTotals[0][a.toLowerCase()] = tempA.length;
      // a[0][key] = parseInt(k['racks']);
    });


    console.log(returnArray);
    setTempCard(returnArray);
    //console.log(tempCard);
  }



  const exportData = (e) => {
    var a = [];
    var k = 0;
    var addedem = prompt("How many stores per year?", "10");

    if (addedem == null) {
      return false;
    }

    addedem = parseInt(addedem).toFixedNumber(0);
    var y = new Date().getFullYear();
    var g = parseInt(totals[0].gwpContribution);
    var l = parseInt(totals[0].lbs);
    var t = parseFloat(g / l).toFixedNumber(0);
    //console.log(targets);


    for (let index = 0; index < 10; index++) {
      a.push({
        racks: parseInt(totals[0].racks) + k,
        year: y + index,
        wgp: t,
        2025: ((t <= targets.gwpTarget.twentytwentyfive ? 'Compliant' : 'Non-compliant')),
        2030: ((t <= targets.gwpTarget.twentythirty ? 'Compliant' : 'Non-compliant')),
        lbs: l,
        gwpContribution: g,
        added: addedem
      });
      k = k + addedem;
      g = g + addedem * 500;
      l = l + addedem * 500;
      t = parseFloat(g / l).toFixedNumber(0);
    }
    //  console.log(a);
    setOutputTable(a);
    setShowOutput(false);
    setOpen(true);
    document.getElementById("out").scrollIntoView();
    const data = a;
    const fileName = 'download';
    const exportType = exportFromJSON.types.csv;

    // exportFromJSON({ data, fileName, exportType });



  }

  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  function getModalStyle() {
    const top = 15 ;
    const left = 25 ;
    const right = 25;

    return {
      top: `${top}%`,
         margin:'auto',
         left: `${left}%`,
         right: `${right}%`
      //transform: `translate(-${top}%, -${left}%)`,
    };
  }



  const handleCloseModal = () => {
    setOpen(false);
  };

  async function fetchData(id) {
    const res = await fetch("https://gwp-api.digitalwerksinnovations.com/calculatedgwp/" + id);
    // console.log(res);
    res
      .json()
      .then((res) => {
        res.push({
          projectId: id,
          refrigerant: DefautRef.name.toUpperCase(),
          racks: 0,
          lbs: 0,
          gwpContribution: 0
        });
        var d = [];
        _.forEach(res, function (v, k) {
          v.gwpContribution = _.toNumber(v.gwpContribution);
          d.push(v);
        });
        //console.log(d);
        var e = _.sortBy(d, ["gwpContribution"]);
        e = _.reverse(e);
        setCards(e);
        setTempCard(e);
      })
      .then((res) => setLoading(false))
      .catch((err) => setErrors(true));
    // .finally((res) => buildPreWork(res))

    const res2 = await fetch("https://gwp-api.digitalwerksinnovations.com/calculatedgwptotals/" + id);

    if (res2.status === 200) {
      // console.log(res2);
      res2
        .json()
        .then((res2) => setTotals(res2))
        .then((res) => setLoading(false))
        .catch((err) => setErrors(true));
    }

    const res3 = await fetch("https://gwp-api.digitalwerksinnovations.com/project_detail/" + id);

    if (res3.status === 200) {
      // console.log(res2);
      res3
        .json()
        .then((res3) => buildPreWork(res3))
        .then((res) => setLoading(false))
        .catch((err) => setErrors(true));
    }

    const res4 = await fetch("  https://gwp-api.digitalwerksinnovations.com/refrigerants/select");

    if (res4.status === 200) {
      //  console.log(res4);
      res4
        .json()
        .then((res4) => {
          //console.log(res4);
          //console.log(cards);
          setSelectRef(res4);

        })
        .catch((err) => setErrors(true));
    }
  }

  useEffect(() => {
    fetchData(props.pid);
    // buildPreWork();
  }, []);
  return (
    <div >

      <Grid className={classes.mattable} container spacing={3}>


        <Grid item lg={3}>
          <Typography style={{ backgroundColor: compliantClass2025 }} className={classes.paper}>
            2025 Target: {compliance2025}
          </Typography>
        </Grid>
        <Grid item lg={3}>
          <Typography style={{ backgroundColor: compliantClass2030 }} className={classes.paper}>
            2030 Target: {compliance2030}
          </Typography>
        </Grid>

        <Grid item lg={3}>
          <Button className={classes.refresh} variant="outlined" size="large" color="primary" onClick={() => {
            exportData();
          }}>Export Result</Button>
        </Grid>

        <Grid item lg={3}>
          <InputLabel id="demo-simple-select-label" className={classes.title}>Default Refrigerant</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="newRefer"
            variant="standard"
            defaultValue={DefautRef.name.toUpperCase() + '-' + DefautRef.value}
            fullWidth
            onChange={(e) => {
              var r = e.target.value.split('-');
              var a = _.find(Object.keys(cards), { 'refrigerant':r[0].toLowerCase() });
              cards.push({
                projectId: props.pid,
                refrigerant: r[0],
                racks: 0,
                lbs: 0,
                gwpContribution: 0
              })
         
              setDefaultRef({ name: r[0].toLowerCase(), value: parseInt(r[1]) });
              console.log(a);
            }}
            size="large"
            color="primary"
          >

            {SelectRef.map((item) =>
              <MenuItem value={item.name + '-' + item.gwp_value}>{item.name} - {item.gwp_value}</MenuItem>
            )}


          </Select>
        </Grid>

        <Grid item lg={3} md={6}>
          <Paper elevation={0} >
            <Typography className={classes.paper}> Total Racks: {totals[0].racks} </Typography>

          </Paper>
        </Grid>
        <Grid item lg={3}>
          <Paper elevation={0} >
            <Typography className={classes.paper}> Total Lbs: {numberWithCommas(totals[0].lbs)}</Typography>

          </Paper>
        </Grid>
        <Grid item lg={3}>
          <Paper elevation={0} >
            <Typography className={classes.paper}> Total GWP: {numberWithCommas(totals[0].gwpContribution)} </Typography>

          </Paper>
        </Grid>
        <Grid item lg={3} >
          <Paper>

            <Typography className={classes.paper}> WAGWP: {(totals[0].gwpContribution / totals[0].lbs).toFixed(0)}</Typography>

          </Paper>
        </Grid>
      </Grid>
      
      <MaterialTable
        isLoading={loading}
        hasError={hasError}
        data={cards}
        title={<Typography> Calculated GWP </Typography>}
        options={{
          search: false,
          pageSize: 8,
          paging: false,
          showTitle: false
          //  exportButton:true,
          //   exportFileName: props.pid
          //  headerStyle: {backgroundColor: "#5c74ab"},
        }}
        columns={[
          {
            title: "projectId",
            field: "projectId",
            hidden: true,
          },

          {
            title: "Refrigerant",
            field: "refrigerant",
          },
          /* {
            title: "",
            field: "",
            render: (rowData) => (
              <TextField
                id="standard-basic"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: "0",
                  step: "1",
                  data_ref: rowData.refrigerant,
                  data_id: rowData.projectId,
                }}
                type="number"
                value={refridgeTotals[0][rowData.refrigerant.toLowerCase()]}
                onInput={updateRefrigerant}
                label="Adjust"
                size="small"
                variant="outlined"
                

              />
            ),
          },*/
          {
            title: "Adjust",
            field: "",
            render: (rowData) => (
              <Button
                className={classes.adjust}
                variant="outlined"
                id={rowData.refrigerant}
                data-ref={rowData.refrigerant}
                data-id={rowData.projectId}
                value={refridgeTotals[0][rowData.refrigerant.toLowerCase()]}
                onClick={updateRefrigerant}
                endIcon={<ArrowDownwardIcon >Move</ArrowDownwardIcon>}
              >{refridgeTotals[0][rowData.refrigerant.toLowerCase()]}

              </Button>
            ),
          },
          {
            title: "LBS",
            field: "lbs",
            type: "numeric",
            render: (rowData) => (
              <Typography>

                {numberWithCommas(parseInt(rowData.lbs))}
              </Typography>
            ),
          },
          {
            title: "GWP Contribution",
            field: "gwpContribution",
            type: "numeric",
            render: (rowData) => (
              <Typography>

                {numberWithCommas(parseInt(rowData.gwpContribution))}
              </Typography>
            ),
          },
          {
            title: "Contribution",
            type: "numeric",
            render: (rowData) => (
              <Typography>

                {(
                  (parseInt(rowData.gwpContribution) /
                    parseInt(totals[0].gwpContribution)) *
                  100
                ).toFixed(0)}
                %
              </Typography>
            ),
          },
        ]}
      />
      <Link id="out" href="#"></Link>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={modalStyle}
      >

        <Hidden xsUp={showOutput}>

          <Grid className={classes.mattable} container spacing={3}>

            <Grid item lg={12} >
            <IconButton aria-label="delete" className={classes.margin}>
            <CancelIcon fontSize="large" onClick={handleCloseModal} />
          </IconButton>
              <MaterialTable
                data={outputTable}
                title="Output Table"
                columns={[{
                  title: "Year",
                  field: "year"
                },
                {
                  title: "Racks Added",
                  field: "added"
                },
                {
                  title: "Racks",
                  field: "racks"
                },
                {
                  title: "WAGWP",
                  field: "wgp"
                },
                {
                  title: "2025 Compliance - " + targets.gwpTarget.twentytwentyfive,
                  field: "2025"
                }, {
                  title: "2030 Compliance",
                  field: "2030"
                }
                ]}
                options={{
                  search: false,
                  showTitle: true,
                  pageSize: 11,
                  paging: false,
                  exportButton: true,
                  exportFileName: props.pid
                }}
              ></MaterialTable>
            </Grid>

          </Grid>
        </Hidden>


      </Modal>


      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Project Saved"
      />

    </div>
  );
};

GwpCalculationResults.propTypes = {};

export default GwpCalculationResults;
