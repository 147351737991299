import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../logo.png';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import themeFile from '../theme.json';
import appVars from '../appVars.json';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Backdrop } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';


function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://digitalwerksinnovations.com/">
              {appVars.copyright}
    </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
      </Typography>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw'
  },
  image: {
    backgroundImage: themeFile.palette.appBar.background,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    maxWidth: '300px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
      rightside:{
        background:themeFile.palette.appBar.secondary
      }
}));

export default function SignUp() {
  const classes = useStyles();
  const theme = createMuiTheme(themeFile);
  const [hasError, setErrors] = useState(false);
  const [open, setOpen] = useState(false);


  async function postData(url, data) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: data, // body data type must match "Content-Type" header,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.json(); // parses JSON response into native JavaScript objects
  }



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setErrors(false);
  };


  return (





    <ThemeProvider theme={theme}>



      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} xl={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} xl={5} component={Paper} className={classes.rightside} elevation={6} square>
          <div className={classes.paper}>
            <img src={logo} className={classes.logo} alt="DigitalWerks Logo" />

            <Typography component="h1" variant="h5">
              Sign up
        </Typography>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} >
                  <TextField
                    autoComplete="fname"
                    name="name"
                    variant="outlined"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                    label="I want to be contacted via email."
                  />
                </Grid>
              </Grid>
              <Button

                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => {

                  setOpen(true);
                  const formData = {
                    "name": document.getElementById('name').value,
                    "passwd": document.getElementById('password').value,
                    "email": document.getElementById('email').value
                  };

                 // console.log(JSON.stringify(formData));


                  postData('https://gwp-api.digitalwerksinnovations.com/users/add', JSON.stringify(formData))
                    .then(data => {
                      if (data.pkRecId) {
                         console.log(data);
                        setTimeout(() => { setOpen(false); window.location = "/login"; }, 1500);
                      } else {

                        setErrors(true);
                      }
                    });



                }}
              >
                Sign Up
          </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link href="/login" variant="body2">
                    Already have an account? Sign in
              </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                                <Copyright />
                            </Box>
            </form>
          </div>
        </Grid>
      </Grid>

      <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}  >
        <Alert onClose={handleClose} severity="error">
          Your email or password is incorrect, I can't let you in. 403: Access Denied!
                </Alert>
      </Snackbar>

      <Backdrop open={open} className={classes.backdrop}>
        <CircularProgress size={48} />
      </Backdrop>

    </ThemeProvider>



  );
}