import React from 'react';
import { Typography, Divider, Grid,  Paper ,TextField} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import NavBreadCrumb from '../../NavBreadCrumb';
import { Settings } from '@material-ui/icons';


const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    paper: {
        padding: '1em',
        marginTop: '1em'
    },
    title: {
        borderBottom: '1px solid #000',
    },
    pos: {
        marginBottom: 12,
    },
});


const SettingsDashboard = ({ props }) => {
    const classes = useStyles();


    var settings = {"gwptarget": 1400};




    return (
        <div>
           <NavBreadCrumb></NavBreadCrumb>
            <Divider></Divider>
            <Grid container xl={12} spacing={2}>
                <Grid item lg={3}>
                    <Paper className={classes.paper}>

                        <List dense>
                            <Typography className={classes.title} variant="h5">Refrigerants</Typography>
                            <ListItem>
                                <ListItemText >
                             
                                        <Link  href="/settings/refrigerants">A1 Refrigerants</Link> 
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText >
                                    <Link>Future Use</Link>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Paper>
                </Grid>



                <Grid item lg={3}>
                    <Paper className={classes.paper}>
                        <List dense>
                            <Typography className={classes.title} variant="h5">System Settings</Typography>
                            <ListItem>
                                <ListItemText >
                                    <Link  href="/settings/sausers">Users</Link>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText > 
                              
                                    <Link href="/settings/refrigerants">Global GWP Target</Link>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Paper>
                </Grid>
                <Grid item lg={3}>
                    <Paper className={classes.paper}>
                        <List dense>
                            <Typography className={classes.title} variant="h5">Global GWP Target</Typography>
                            <ListItem>
                                <ListItemText >
                                   
                                <TextField id="outlined-basic" value={settings.gwptarget}  variant="outlined" />
                                </ListItemText>
                            </ListItem>
                            
                        </List>
                    </Paper>
                </Grid>


            </Grid>
        </div>
    );
};

SettingsDashboard.propTypes = {

};

export default SettingsDashboard;
