import React from 'react';
import {style} from './index.scss';
import GWPcalculated from './LightCalculated';


const Lightversion = (props) => {

    return (
        <div className={style}>
    <GWPcalculated>

    </GWPcalculated>
        </div>
    );
};

Lightversion.propTypes = {

};

export default Lightversion;
