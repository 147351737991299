import React from 'react';
import { style } from './index.scss';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ProjectTable from './Projects_Table';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    head: {
        borderBottom: "1px solid"
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.light
    },
    paper: {
        padding: "1em"
    },
    divider: {
        marginBottom: "1em",
        marginTop: "1em"
    }
}));


const DashBoard = (props) => {
    const classes = useStyles();


    return (
        <div className={style}>

            <Grid container spacing={3} >
                <Grid item xl="auto" xs={12}>
                    <Button color="default" variant="outlined" href="/project/add">New Project</Button>
                </Grid>

            </Grid>
            <Divider className={classes.divider}></Divider>

            <Grid container spacing={3} >

                <Grid item lg={12} xl={12}>
                    <ProjectTable></ProjectTable>
                </Grid>
                <Grid item lg={12} xl={12}>
                </Grid>
            </Grid>



        </div>
    );
};

DashBoard.propTypes = {

};

export default DashBoard;
