import React from 'react';
import { Redirect} from 'react-router-dom';
import { setCookie } from '../dwi-functions';
// eslint-disable-next-line
const Logout = ({}) => {
    setCookie('_au', "", 7);
    return (  
    <Redirect to="/login" />
    );
};

Logout.propTypes = {

};

export default Logout;
