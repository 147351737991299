export function getUrlParameter(name) {
    // eslint-disable-next-line  
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};



export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


 export function loadSplash(lightboxSettings) {

  //console.log(lightboxSettings);
  var dwiSplash = document.createElement('div');
  var im = JSON.parse(lightboxSettings.image);
  var link = document.createElement('link');

  // set the attributes for link element  
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = 'http://localhost:3000/a.css';

  //build markup for splash
  dwiSplash.setAttribute('class', 'dwiSplash');
  dwiSplash.innerHTML = '<div id="dl-background-splash-1"><div class=close><a id="close" onclick="sendClick(\'closed\',\'' + lightboxSettings.description + '\');">X</a></div><div class=donate-now><a href="' + lightboxSettings.url + '"onclick="sendClick(\'clicked\',\'' + lightboxSettings.description + '\');" ><img alt="' + im.imageAlt + '" title="' + im.imageAlt + '" src="' + im.imageSrc + '"/></a></div></div></div>';
  dwiSplash.appendChild(link);

  //make sure we are at the top of the page
  window.scrollTo(0, 0);

  //insert mark-up
  document.body.appendChild(dwiSplash);
  setCookie('DWI-' + lightboxSettings.lightbox_id, '1', lightboxSettings.cookie_expire);
  setTimeout(function () {
      document.getElementById("dl-background-splash-1").className += "load";
      document.getElementById('dl-background-splash-1').style["max-width"] = lightboxSettings.maxWidth;
      document.getElementById('close').style["color"] = lightboxSettings.themeColor;
  }, lightboxSettings.delay);

  window.dataLayer.push({
      'event': 'customEvent',
      'eventInfo': {
          'category': 'Lightbox Engagement',
          'action': 'Impression',
          'label': lightboxSettings.description,
          'value': 0,
          'nonInteraction': true
      }
  });
}


export function GUID() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}


export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}