import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';

import MaterialTable from 'material-table'
import {numberWithCommas } from '../../dwi-functions';




const ProjectDetail = (props) => {
 
    
    const [hasError, setErrors] = useState(false);
    const [cards, setCards] = useState([]);
    const [SelectRef, setSelectRef] = useState();
    const [loading, setLoading] = useState([true]);

    async function fetchData(id) {
        const res = await fetch("https://gwp-api.digitalwerksinnovations.com/project_detail/"+id);
       // console.log(res);
        res
            .json()
            .then(res => setCards(res))
            .then(res => setLoading(false))
            .catch(err => setErrors(true));
    }

    
    async function postData(url, data) {
        // Default options are marked with *
        const response = await fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          body: data, // body data type must match "Content-Type" header,
          headers: {
            'Content-Type': 'application/json'
          }
        });
      
        return response.json(); // parses JSON response into native JavaScript objects
      }

      async function fetchSelectRef() {
        const res = await fetch("https://gwp-api.digitalwerksinnovations.com/refrigerants/select");
        res
        .json()
        .then(res => setSelectRef(res));
       // console.log(res.json());
    }

    
    const sr = {};


    if(SelectRef){
    SelectRef.map(client => {
        const { name, gwp_value } = client;
        sr[ gwp_value ] = name
    })
    //console.log(sr);
  }



      useEffect(() => {
        fetchData(props.pid);
        fetchSelectRef();
    }, []);

    return (
        <MaterialTable
        isLoading={loading}
        hasError={hasError}
        columns={[
            { title: 'pkRecID', field: 'pkRecID', hidden: true },
            { title: 'Location Name', field: 'location' },
            { title: 'System Type', field: 'systemType' },
            { title: 'MFG', field: 'mfg' },
            { title: 'Refrigerant', field: 'refrigerant', lookup: sr,render: rowData => <Typography>{rowData.refrigerant}</Typography>  },
            { title: 'GWP Value', field: 'lookupRefrigerant',export: false},
            { title: 'Estimated Charge', field: 'estimateCharge' ,render: rowData => <Typography>{numberWithCommas(parseInt(rowData.estimateCharge))}</Typography>  },
            { title: 'Current Total GWP' , field: 'currentgwp'}
        ]}
        data={cards}
        editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                const data = {
                    location: newData.location,
                    projectId: props.pid,
                    systemType: newData.systemType,
                    
                    refrigerant: newData.refrigerant,
                    estimateCharge: newData.estimateCharge,
                    mfg: newData.mfg
                };
              //  console.log(JSON.stringify(data));
                const u = 'https://gwp-api.digitalwerksinnovations.com/project_detail/add/';
                const res = postData(u,JSON.stringify(data));
                res
                .json();
                  //fetchData(getCookie('_au'));
                  resolve();
                 window.location.reload();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = {
                  location: newData.location,
                  projectId: props.pid,
                  systemType: newData.systemType,
                  refrigerant: newData.refrigerant,
                  estimateCharge: newData.estimateCharge,
                  mfg: newData.mfg
              };
                //console.log(data); 
                const index = oldData.pkRecId;
                const u = 'https://gwp-api.digitalwerksinnovations.com/project_detail/edit/'+index;
                setLoading(true);
                postData(u,JSON.stringify(data));
                fetchData(props.pid);
                window.location.reload();
                  resolve()
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
              const index = oldData.pkRecId;
              const u = 'https://gwp-api.digitalwerksinnovations.com/project_detail/delete/'+index;
              const res = fetch(u);
              window.location.reload();
                resolve()
              }),
          }}
        title="Details"
        options={{
            grouping: true,
            exportButton: true,
            exportAllData: true,
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 50]
          }}

      />
    );
};

ProjectDetail.propTypes = {

};

export default ProjectDetail;
