import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'




function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}




const ProjectsTable = ({ props }) => {

  const [hasError, setErrors] = useState(false);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState([true]);


  async function fetchData(id) {
    const res = await fetch("https://gwp-api.digitalwerksinnovations.com/projects/" + id);
    // console.log(res);
    res
      .json()
      .then(res => setCards(res))
      .then(res => setLoading(false))
      .catch(err => setErrors(true));
  }


  useEffect(() => {
    fetchData(getCookie('_au'));
  }, []);


  return (

    <MaterialTable
      isLoading={loading}
      hasError={hasError}
      columns={[
        { title: 'pkRecID', field: 'pkRecID', hidden: true },
        { title: 'Project Name', field: 'name' },
        { title: 'Description', field: 'description' }
      ]}
      data={cards}
      title="Projects"
      actions={[
        {
          icon: 'edit',
          tooltip: 'Edit Project',
          onClick: (event, rowData) => {
            // Do save operation
            window.location = "/project/edit/" + rowData.projectId
            //              console.log(rowData);
          }
        },
        {
          icon: 'delete',
          tooltip: 'Delete Project',
          onClick: (event, rowData) => {
            // Do save operation
            const u = 'https://gwp-api.digitalwerksinnovations.com/projects/delete/' + rowData.projectId;
            const res = fetch(u);
            if (res) {
              fetchData(getCookie('_au'));
              window.location.reload();
              //console.log(rowData);
            }
          }
        },
        {
          icon: 'print',
          tooltip: 'Print Project',
          onClick: (event, rowData) => {
            // Do save operation
            //const u = 'https://gwp-api.digitalwerksinnovations.com/projects/delete/'+rowData.projectId;
            //const res = fetch(u);
            //fetchData(getCookie('_au'));
            console.log(rowData);

          }
        },
        {
          icon: 'add',
          tooltip: 'Add Project',
          isFreeAction: true,
          onClick: (event, rowData) => {
            // Do save operation
            window.location.href = 'https://gwp-api.digitalwerksinnovations.com/project/add';

          }
        }

      ]}
    />



  );
};

ProjectsTable.propTypes = {

};

export default ProjectsTable;
