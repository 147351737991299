import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';

const url = window.location.href.split('/');
const paths = [];
const i = 2;

url.map((item, key) =>
    (key <= i ? '' : paths.push(item))
);

const useStyles = makeStyles({
    item: {
        textTransform: 'capitalize'
    }
});


const NavBreadCrumb = ({ props }) => {
    const classes = useStyles();
    return (
        <Breadcrumbs aria-label="breadcrumb">
            {paths.map((item, key) =>
                    <Typography id={key} className={classes.item} color="textPrimary">{item}</Typography>
      
            )}
        </Breadcrumbs>
    );
};

NavBreadCrumb.propTypes = {

};

export default NavBreadCrumb;
