import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    button: {
        borderStyle: 'solid',
        border: '1px',
        borderColor: '#fff',
        color: '#fff',
        marginRight: "7px"
    },
    image: {
        maxWidth: '70%',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    drop: {
        width: '100%',
        marginLeft: '0',
        marginRight: 'auto',
        marginTop: theme.spacing(3)
    },
    dropButton: {
        marginBottom: theme.spacing(2)
    }

}));





const ProjectDetailsUpload = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);


    async function uploadFiles(files) {

        const formData = new FormData();
        formData.append('fileToUpload', files[0]);
        formData.append('submit', true);
        formData.append('pid', props.pid)

        const res = await fetch("https://gwp-api.digitalwerksinnovations.com/upload", {
            method: "POST",
            mode: "no-cors",
            body: formData
        });
        if (res.body !== null) {
            res
                .json()
                .then(res => console.log(res));
        }

        //console.log(res);
       
    }

    return (

        <div className={classes.drop}>
            <Button variant="outlined" className={classes.dropButton} color="primary" onClick={() => setOpen(true)}>
                Upload File
            </Button>

            <DropzoneDialog
                open={open}
                dropzoneClass={classes.drop}
                filesLimit={1}
                onClose={() => setOpen(false)}
                onSave={(files) => {
                    if (files.length >= 1) {
                        uploadFiles(files);
                        setOpen(false);
                        window.location.reload();
                    }
                }} /></div>
    )
};

ProjectDetailsUpload.propTypes = {

};

export default ProjectDetailsUpload;
